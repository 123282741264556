<div class="center">
  <div class="row">
    <div class="card">
      <div>
        <div class="card-header">
          Oops!
        </div>

        <div class="card-block">
          <div class="card-title">
            404 Not Found
          </div>

          <div class="card-text">
            <div>
              Sorry, an error has occured, Requested page not found!
            </div>
          </div>
        </div>

        <div class="card-footer">
          <div>
            <a routerLink="/">
              <button class="btn-primary btn btn-sm">
                <clr-icon shape="home"></clr-icon>
                Take Me Home
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

