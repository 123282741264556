import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AppComponent} from './app.component';
import {NotFoundComponent} from './not-found/not-found.component';

// routes
const appRoutes: Routes = [
  {path: '', redirectTo: 'app/transactions', pathMatch: 'full'},
  {path: 'transactions', redirectTo: 'app/transactions', pathMatch: 'full'},
  {path: 'imports', redirectTo: 'app/imports', pathMatch: 'full'},
  {
    path: 'app',
    component: AppComponent,
    children: [
      {
        path: 'transactions',
        loadChildren: () => import('./model/transactions/transactions.module').then(m => m.TransactionsModule)
      },
      {
        path: 'imports',
        loadChildren: () => import('./model/import-process/import-process.module').then(m => m.ImportProcessModule)
      }
    ]
  },
  {path: '**', component: NotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
