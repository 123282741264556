import { Component } from '@angular/core';
import { AuthService } from '../../security/auth.service';

declare var require: any;

const {version: appVersion} = require('../../../../package.json');

@Component({
  selector: 'app-left-menu',
  templateUrl: './application-left-menu.component.html',
  styleUrls: ['./application-left-menu.component.scss']
})
export class ApplicationLeftMenuComponent {

  public appVersion;
  collapsible = true;
  collapse = true;

  constructor(public authService: AuthService) {
    this.appVersion = appVersion;
  }
}
