import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../security/auth.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AuthCognitoService } from '../../auth/auth-cognito.service';
import {AppConfigService} from '../../config/app-config.service';

const {version: appVersion} = require('../../../../package.json');

declare var require: any;


@Component({
  selector: 'app-top-menu',
  templateUrl: './application-top-menu.component.html',
  styleUrls: ['./application-top-menu.component.scss']
})
export class ApplicationTopMenuComponent  implements OnInit {

  public appVersion;
  userDetails;
  lang = 'fr';
  open = false;
  public serviceDeskLink;
  public serviceDeskName;
  public groupName;
  constructor(public authService: AuthService,
              private toastr: ToastrService,
              private cognitoService: AuthCognitoService,
              public translate: TranslateService) {

    this.appVersion = appVersion;
    this.translate.addLangs(['nl', 'fr', 'en']);
    this.translate.setDefaultLang('fr');
    this.translate.use('fr');
    this.serviceDeskLink = AppConfigService.settings.serviceDeskLink;
    this.serviceDeskName = AppConfigService.settings.serviceDeskName;
    this.groupName = AppConfigService.settings.groupName;
  }

  async ngOnInit() {
    const isAuthenticated = await this.cognitoService.isAuthenticated();
    if (isAuthenticated === false) {
      this.cognitoService.federatedSignIn();
    }
    this.userDetails = await this.cognitoService.getUser();
    const hasPermission = this.authService.hasPermission(this.userDetails.attributes);
    if (hasPermission === false){
      this.open = true;
    }else {
      if (localStorage.getItem('language') != null) {
        this.translate.use(localStorage.getItem('language'));
        this.lang = localStorage.getItem('language');
      }
      this.toastr.info('Cognito session active', 'Welcome back ' + this.userDetails.attributes.given_name);
    }
  }

  async doLogout() {
    await this.cognitoService.signOut();
  }

  doChangeLang(language: string) {
    this.lang = language;
    localStorage.setItem('language', language);
    this.translate.use(language);
  }

}
