<clr-header class="app-header">
  <div class="branding">
    <div class="title" style="flex: 1">
      <a routerLink="/">
        <img alt="" src="assets/images/TotalEnergies_Logo_RGB.png">
        <span class="app-title">Green Management Tool</span>
      </a>
    </div>
  </div>

  <div class="settings">
    <clr-dropdown>
      <button class="dropdown-toggle btn btn-link user-dropdown" clrDropdownTrigger>
        <clr-icon class="user-icon" shape="user"></clr-icon>
        <clr-icon class="caret-icon" shape="caret down"></clr-icon>
        {{userDetails?.attributes?.family_name}} {{userDetails?.attributes?.given_name}}
        <clr-icon class="caret-icon" shape="caret down"></clr-icon>
      </button>

      <clr-dropdown-menu *clrIfOpen clrposition="bottom-right">
        <div class="dropdown-menu-container">
          <h4 class="dropdown-header">Session</h4>
          <a clrDropdownItem (click)="doLogout()">Log out</a>
          <h4 class="dropdown-header">Language</h4>
          <clr-dropdown>
            <button class="dropdown-toggle btn btn-link" clrDropdownTrigger>
              {{lang}}
            </button>
            <clr-dropdown-menu *clrIfOpen clrPosition="left">

              <a clrDropdownItem (click)="doChangeLang('fr')">Français</a>
              <a clrDropdownItem (click)="doChangeLang('nl')">Nederlands</a>
              <a clrDropdownItem (click)="doChangeLang('en')">English</a>
            </clr-dropdown-menu>
          </clr-dropdown>
        </div>
      </clr-dropdown-menu>
    </clr-dropdown>
  </div>
</clr-header>
<clr-modal [(clrModalOpen)]="open" [clrModalStaticBackdrop]="true" [clrModalClosable]="false">
  <h3 style="color: red; font-weight: bold;" class="modal-title">Access denied</h3>
  <div class="modal-body">
    <p style="font-size:115%;">You don't have the rights to use this application.<br><br>
      Please create a ticket and ask to be added to the groups : <br><br>
      <b>{{groupName}}</b>
      <br><br>
      Link to Service Desk request: &nbsp;<a style="font-weight: bold;"
                                             href="{{serviceDeskLink}}" target="_blank"
                                             rel="noreferrer noopener">{{serviceDeskName}}</a>
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn-warning btn-sm btn"
            (click)="doLogout()">{{'application.logout' | translate}}</button>
  </div>
</clr-modal>
