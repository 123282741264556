import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(public translate: TranslateService) {
    this.translate.addLangs(['nl', 'fr', 'en']);
    this.translate.setDefaultLang('fr');
    this.translate.use('fr');
  }
}
