<clr-vertical-nav class="nav-trigger--bottom h-100 app-nav"
                  [clrVerticalNavCollapsible]="collapsible"
                  [(clrVerticalNavCollapsed)]="collapse">

  <a clrVerticalNavLink routerLink="transactions" routerLinkActive="active">
    <clr-icon clrVerticalNavIcon shape="euro" class="is-solid"></clr-icon>
    Transactions
  </a>
  <a clrVerticalNavLink routerLink="imports" routerLinkActive="active">
    <clr-icon clrVerticalNavIcon shape="upload-cloud" class="is-solid"></clr-icon>
    Imports
  </a>
  <div class="nav-divider"></div>

  <span class="version" *ngIf="!collapse">version : {{appVersion}}</span>

</clr-vertical-nav>
