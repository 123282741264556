import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {IAppConfig} from './app-config.model';
import {firstValueFrom} from 'rxjs';
import {CognitoConfigService} from './cognito-config.service';


@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  static settings: IAppConfig;
  private readonly CONFIG_URL = 'assets/config/config.json';

  constructor(private http: HttpClient, private cognitoConfigService: CognitoConfigService) {
  }

  load(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      firstValueFrom(this.http.get(this.CONFIG_URL)).then((response: IAppConfig) => {
        AppConfigService.settings = response as IAppConfig;
        this.cognitoConfigService.load();
        resolve();
      }).catch((response: any) => {
        reject(`Could not load file '${this.CONFIG_URL}': ${JSON.stringify(response)}`);
      });
    });

  }
}
